<template>
<div>
  <h1 class="header">Preferencias de inversión</h1>
  <div style="display:flex;">
    <div class="first-three">
      <span class="title">Cartera:</span>
      <div>
        <SelectOutline class="select-portfolio" :helper_select_permission="false" :default_value="default_value" :label_name="portfolioJson.label_name" :options="portfolio_list" :component_class="portfolioJson.component_class" :helper_text_select="portfolioJson.helper_text_select"  @_emit_method_order_select="_selectValue"/>
      </div>
      <div>
        <Button id="portfolio" class="preference-button" @_emit_method_button="_openPortfolioUpdate" :button_name="button_name" :button_key="button_key" />
      </div>
      <div>
        <Button id="portfolio" class="preference-button-balance" @_emit_method_button="_openPortfolioUpdateBalance" :button_name="button_name2" :button_key="button_key2" />
      </div>
    </div>
    <div class="portfolio-information">
        <span class="mid-info">Valor cuenta (EUR):</span>
        <InputTextOutlineDisabled :computed_value="valor_cuenta" :computed_value_permission="true" class="input-disabled"/>
        <span class="mid-info">Divisa:</span>
        <InputTextOutlineDisabled :computed_value="divisa" :computed_value_permission="true" class="input-disabled"/>
        <span class="mid-info">% Riesgo por operación:</span>
        <InputTextOutlineDisabled :computed_value="riesgo_operacion" :computed_value_permission="true" class="input-disabled"/>
        <span class="mid-info">Estrategia de riesgo:</span>
        <SelectOutlineDisabled :options="options" :default_value="select_value" class="input-disabled"/>
    </div>
    <div class="graficator-buttons">
      <div>
        <a href="/app/graficador-dinamico/">
          <Button id="portfolio" class="preference-button-value-list" @_emit_method_button="_openValueList" :button_name="button_name3" :button_key="button_key3" />
        </a>
      </div>
      <div>
        <a href="/app/graficador-dinamico/">
          <Button id="portfolio" class="preference-button-dinamic-graficator" @_emit_method_button="_openDinamicGraficator" :button_name="button_name4" :button_key="button_key4" />
        </a>
      </div>
    </div>
  </div>
  <div>
  
  </div>
</div>
</template>

<script>
import PortfolioPreferences from "@/views/configs/portfolio_preferences.json";
import Button from "@/components/helpers/Button";
import SelectOutline from "@/components/helpers/SelectOutline.vue";
import APICaller from "@/components/codeComponents/APICaller.js";
import InputTextOutlineDisabled from "@/components/helpers/InputTextOutline_disabled";
import SelectOutlineDisabled from "@/components/helpers/SelectOutline_disabled";
export default {
  mixins: [APICaller],
  name: "InvestPreferences",
  components: {
    SelectOutline,
    Button,
    InputTextOutlineDisabled,
    SelectOutlineDisabled
  },
  data() {
    return {
      portfolioJson: PortfolioPreferences,
      portfolio_list: [],
      default_value: "",
      portfolios: [],
      button_name: "Modificar Cartera",
      button_key: "update-portfolio-button-key",
      button_name2: "Modificar saldo de cuenta",
      button_key2: "update-portfolio-balance-button-key",
      valor_cuenta: "",
      divisa: "",
      riesgo_operacion: "",
      options: [
        {"name": "", "value": ""},
        {"name": "Precio de soporte", "value": "Precio de soporte"},
        {"name": "precio de stop", "value": "precio de stop"},
      ],
      select_value: "",
      button_name3: "Ir a listado de valores",
      button_key3: "list-values-button-key",
      button_name4: "Ir al graficador dinámico",
      button_key4: "dinamic-graficator-button-key",      
    };
  },
  created(){
    this._getPortfolios();
    
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    _selectValue: function(value){
      this.portfolios.forEach((element)=>{
        if(element.idc==value[0]){
          this.valor_cuenta = element.real_capital;
          this.divisa = element.currency;
          this.riesgo_operacion = element.risk;
          this.select_value = element.risk_strategy
        }
      })
    },
    _getPortfolios: function (){
            let success = response =>{
                let datos = response.data;
                datos.forEach((element) =>{
                  let portfolio = {"name": element.idc, "value": element.name}
                  this.portfolios.push(element)
                  this.portfolio_list.push(portfolio)
                });
              }
              
              let url = '/api/v1/portfolio/list';
              
              const successHandler = new APICaller.SuccessHandler(success);
              const failureHandler = new APICaller.FailureHandler('199', 'Error recuperando carteras.');

              this._getAPICall(url, successHandler, failureHandler);
    },
    _openPortfolioUpdate: function(){
      
    },
    _openPortfolioUpdateBalance: function(){
      
    },
    _openValueList: function(){
      
    },
    _openDinamicGraficator: function(){
      
    },
  }
};
</script>

<style lang="scss" scoped>
@use "@material/checkbox"; // Required only for data table with row selection.
@use "@material/icon-button"; // Required only for data table with column sorting.
@use "@material/data-table/data-table";

@include checkbox.core-styles;
@include icon-button.core-styles;
@include data-table.core-styles;
@include data-table.theme-baseline;
.header{

}
.preference-button{
  font-size:13px !important;
  margin-left:200px !important;
  margin-top: 20px;
}
#update-portfolio{
  margin-top: 30px;
  margin-left: 30px;
}
.preference-button-balance{
  margin-top: 20px;
  font-size:13px !important;
  margin-left:200px !important;
}
.select-portfolio{
  width: 300px;
  margin-top: 20px;
  margin-left: 200px;
}
.title{
  margin-left: 200px;
}
.preference-button-value-list{
  margin-top: 20px;
  font-size:13px !important;
  margin-left:10px !important;
}
.preference-button-dinamic-graficator{
  margin-top: 20px;
  font-size:13px !important;
  margin-left:10px !important;
}
.input-disabled :hover{
  cursor: not-allowed;
}
.input-disabled{
  margin-right: 200px;
  margin-left: 200px;
}
.mid-info{
  margin-right: 200px;
  margin-left: 200px;
}
a{
  text-decoration: none;
}
</style>